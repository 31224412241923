import React from 'react'
import { css } from '@emotion/core'

import { mq } from './../components/styles/Layout.styles'
import Overline from './../components/Overline'
import Div from './Div/Div.styles';
import H6 from './H6';
import ds from '../theme'
import { Link } from 'gatsby';
import { normalizeDate } from '../utils/helpers';

const Article = ({
  meta,
  title,
  slug,
  img
}) => {
  const normalizedDate = normalizeDate(meta.date)
  return (
    <div
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: [24]
            } 
          })
        )
      }
    >
      <Div
        mb={[1]}
      >
        <Overline
          selected
          on='onSurface'
          variant='mediumEmphasis'
        >
          {normalizedDate}
          {' '}
          •
          {' '}
          Leitura {meta.duration}
        </Overline>
      </Div>
      <Div
        flexDirection='row'
        alignItems='end'

      >
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            flexGrow: 1,
            height: 'auto'
          }}
        >
          <Link
            style={{
              color: 'inherit'
            }}
            to={`/blog/${slug}`}
          >
            {title}
          </Link>
        </H6>
        <div
          style={{
            width: 70,
            height: 70,
            ...img
            ? {
              background: `url(${img}) center center no-repeat`,
              backgroundSize: 'cover'
            }
            : {
              background: ds.brand('iceBlue'),
            },
            marginLeft: ds.space(3),
            flexShrink: 0,
            justifySelf: 'flex-end'
          }}
        />
      </Div>
    </div>
  )
}

export default Article
