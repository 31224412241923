import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledText
} from './styles/Text.styles'

const Text = ({ children, as, ...props }) => (
  <StyledText
    as={as}
    {...props}
  >
    {children}
  </StyledText>
)

Text.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string
}

Text.defaultProps = {
  fontSize: [14],
  as: 'p'
}

export default Text