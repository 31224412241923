
import styled from '@emotion/styled'
import Atom from './Atom.styles'
import { mq } from './Layout.styles'

/* Default sizes change if you need */
export const StyledText = styled(Atom)(props => (
  mq({
    lineHeight: props.lineHeight
  })
))