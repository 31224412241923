import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types' 
import { graphql, Link }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import ActiveSearch from '../components/ActiveSearch'
import Text from '../components/Text'
import { normalizeDate } from '../utils/helpers';

import H4 from './../components/H4'
import Div from './../components/Div'
import Button from './../components/Button'
import Hr from '../components/Hr'
import Article from '../components/Article'

export const PageTemplate = ({
  data
}) => {
  console.log(data)

  const [postLimit, setPostLimit] = useState(2);
  const [search, setSearch] = useState('');

  const searchQuery = new RegExp(search, 'i')

  useEffect(() => {
    setPostLimit(2)
  }, [search])

  const posts = search
    ? data.allWordpressPost.edges.filter((post) => searchQuery.exec(post.node.title))
    : data.allWordpressPost.edges

  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 728]}
        >
          <Row
            pt={[50, 70]}
            pb={[24]}
            pl={[8, 8, 0, 0]}
            pr={[8, 8, 0, 0]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <div>
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='left'
                >
                  Blog
                </H4>
              </div>
            </Column>
          </Row>
          <Row
            pl={[6, 6, 0, 0]}
            pr={[6, 6, 0, 0]}
          >
            <Div
              maxWidth={['100%', 390, 728]}
            >
              <ActiveSearch 
                mx={[null]}
                onChange={(q) => {
                  setSearch(q)
                }}
              />
            </Div>
          </Row>
        </Div>
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[30]}
            mt={[30, 60]}
            mx={[-16, -16, 0, 0]}
            pl={[8, 8, 0, 0]}
            pr={[8, 8, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              justifyContent={['center', 'flex-end']}
            >
              <Div
                maxWidth={['100%', '100%', 328, 328]}
              >
                {posts[0].node.featured_media
                  ? (
                    <img
                      src={posts[0].node.featured_media.localFile.publicURL}
                      style={{
                        width: '100%',
                        height: 'auto',
                        flexShrink: 0
                      }}
                    />
                  )
                  : (
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(243, 245, 245)' }} />
                  )}
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['flex-start']}
                textAlign={['left']}
              >
                <Div
                  maxWidth={[290, '100%', 352]}
                >
                  <Div
                    style={{
                      fontSize: 11.8,
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      color: 'rgba(0, 0, 0, 0.6)',
                      textTransform: 'uppercase',
                      marginBottom: 8
                    }}
                  >
                    {normalizeDate(posts[0].node.date)} • Leitura 5 min
                  </Div>
                  <Link
                    to={`/blog/${posts[0].node.slug}`}
                    style={{
                      color: 'inherit'
                    }}
                  >
                    <Text
                      fontSize={[23.7]}
                      style={{
                        fontWeight: '600',
                        color: 'rgba(0, 0, 0, 0.87)'
                      }}
                      mb={[5, 5, 3]}
                    >
                      {posts[0].node.title}
                    </Text>
                  </Link>
                </Div>
                <Div
                  display={['block', 'block', 'none']}
                  maxWidth={['100%', 328, 328, 328]}
                >
                  {posts[0].node.featured_media
                  ? (
                    <img
                      src={posts[0].node.featured_media.localFile.publicURL}
                      style={{
                        width: '100%',
                        height: 'auto',
                        flexShrink: 0
                      }}
                    />
                  )
                  : (
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'rgb(243, 245, 245)' }} />
                  )}
                </Div>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={['100%', 422, 728, 728]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 728]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {posts.length > 0
                && Array(Math.ceil(posts.length / 2)).slice(0, postLimit).fill(0).map((item, i) => {
                  return (
                    <Row
                      key={`post-row-${i}`}
                      flexDirection={['column', 'column', 'row']}
                      mt={[40]}
                      maxWidth={['100%', 344, '100%']}
                      width={['100%']}
                    >
                      {posts.slice(1, posts.length).slice(i === 0 ? 0 : i * 2, 2 + i * 2).map((post, x) => (
                        <Column
                          key={`post-item-${x + i}`}
                          width={[1, 1, 1/2]}
                          mb={[24, 24, 0]}
                        >
                          <Article
                            title={post.node.title}
                            slug={post.node.slug}
                            meta={{
                              date: post.node.date,
                              duration: '6 min'
                            }}
                            img={post.node.featured_media && post.node.featured_media.localFile.publicURL}
                          />
                          <Hr
                            lineColor='lightPeriwinkle'
                            maxWidth={[50]}
                            style={{
                              marginLeft: 0
                            }}
                          />
                        </Column>
                      ))}
                    </Row>
                  )
              })}
              {postLimit < Math.ceil(posts.length / 2) && (
                <Button
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['200px']}
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto',
                    marginTop: 50
                  }}
                  onClick={() => {
                    setPostLimit(postLimit + 2)
                  }}
                >
                  Carregar mais
                </Button>
              )}
            </Column>
          </Row>
        </Div>
        {false && (
          <>
            <Hr
              lineColor='lightPeriwinkle'
              m={['0 0 40px']}
              maxWidth={['100%', 422, 728, 728]}
              style={{
                opacity: 0.5
              }}
            />
            <Div
              maxWidth={['100%', 390, 680]}
              alignItems={['center', 'center', 'stretch']}
            >
              <Row
                pb={[50, 70]}
                mx={[-16, -16, -24, -24]}
              >
                <Column
                  width={[1]}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Div
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='left'
                    maxWidth={['100%', 344, '100%']}
                  >
                    <H4
                      selected
                      on='onSurface'
                      variant='highEmphasis'
                    >
                      Mais Lidas
                    </H4>
                  </Div>
                  {posts.length > 0
                    && Array(Math.ceil(posts.length / 2)).fill(0).map((item, i) => {
                      return (
                        <Row
                          flexDirection={['column', 'column', 'row']}
                          mt={[40]}
                          maxWidth={['100%', 344, '100%']}
                          width={['100%']}
                        >
                          {posts.slice(i === 0 ? 0 : i * 2, 2 + i * 2).map((post) => (
                            <Column
                              width={[1, 1, 1/2]}
                              mb={[50, 50, 0]}
                            >
                              <Article
                                title={post.node.title}
                                slug={post.node.slug}
                                meta={{
                                  date: post.node.date,
                                  duration: '6 min'
                                }}
                                img={post.node.featured_media && post.node.featured_media.localFile.publicURL}
                              />
                              <Hr
                                lineColor='lightPeriwinkle'
                                maxWidth={[50]}
                                style={{
                                  marginLeft: 0
                                }}
                              />
                            </Column>
                          ))}
                        </Row>
                      )
                  })}
                  <Button
                    schema='white'
                    outlined
                    selected
                    variant='base'
                    on='onSurface'
                    width={['100%']}
                    maxWidth={['200px']}
                    style={{
                      display: 'flex',
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      margin: '0 auto',
                      marginTop: 50
                    }}
                  >
                    Carregar mais
                  </Button>
                </Column>
              </Row>
            </Div>
          </>
        )}
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Blog' />
      <PageTemplate
        title='Blog'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    image1: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
