export const performScroll = top => {
  /* invoke scroll, with behavior smooth (not supported in Safari as of writing) */
  window.scrollTo({
    behavior: 'smooth',
    top
  })
}

export const scrollToHref = href => {
  /* destination element to scroll to */
  const destinationElement = document.querySelector(href)

  performScroll(destinationElement.offsetTop)
}

// modulo that supports negative numbers (so that e.g. -5 % 4 = 3)
export const modulo = (a, n) => ((a % n) + n) % n

export const normalizeDate = (date) => {
  const normalizedMonth = {
    '01': 'Jan',
    '02': 'Fev',
    '03': 'Mar',
    '04': 'Abr',
    '05': 'Mai',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Ago',
    '09': 'Set',
    '10': 'Out',
    '11': 'Nov',
    '12': 'Dez'
  }

  const [day, month, year] = date.split(' ')

  return [day, normalizedMonth[month], year].join(' ')
}