import React from 'react'
import PropTypes from 'prop-types';
import styled from '@emotion/styled'

import { mq } from './styles/Layout.styles'
import Atom from './styles/Atom.styles'

/* Default sizes change if you need */
export const StyledOverline = styled(Atom)(props => (
  mq({
    lineHeight: [1.36],
    letterSpacing: '2px',
    fontWeight: '600',
    textTransform: 'uppercase'
  })
))

const Overline = ({ children, fontSize, ...props }) => (
  <StyledOverline 
    as='p'
    fontSize={fontSize}
    {...props}
  >
    {children}
  </StyledOverline>
)

Overline.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.array
}

Overline.defaultProps = {
  fontSize: [11.8]
}

export default Overline
